<script>
  import Icon from "./Icon.svelte";

  export let width;
  export let height;
  export let fill;
  export let bg = false;
</script>

<Icon {width} {height} {bg}>
  <svg
    {width}
    {height}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.99512 0C2.23512 0 0.00012207 2.24 0.00012207 5C0.00012207 7.76
      2.23512 10 4.99512 10C7.76012 10 10.0001 7.76 10.0001 5C10.0001 2.24
      7.76012 0 4.99512 0ZM5.00012 9C2.79012 9 1.00012 7.21 1.00012 5C1.00012
      2.79 2.79012 1 5.00012 1C7.21012 1 9.00012 2.79 9.00012 5C9.00012 7.21
      7.21012 9 5.00012 9ZM4.50012 2.5H5.25012V5.125L7.50012 6.46L7.12512
      7.075L4.50012 5.5V2.5Z"
      {fill} />
  </svg>

</Icon>
