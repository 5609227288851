<script>
  import Icon from "./Icon.svelte";

  export let width;
  export let height;
  export let fill;
  export let bg = false;
  export let border = false;
</script>

<Icon {width} {height} {bg} {border}>
  <svg
    {width}
    {height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 0C4.47998 0 0 4.47998 0 10C0 15.52 4.47998 20 10 20C15.52 20 20
      15.52 20 10C20 4.47998 15.52 0 10 0ZM2 10C2 9.38995 2.07996 8.78998
      2.20996 8.21997L6.98999 13V14C6.98999 15.1 7.89001 16 8.98999
      16V17.93C5.06006 17.43 2 14.0699 2 10ZM13.99 14C14.89 14 15.63 14.59 15.89
      15.4C17.1899 13.98 18 12.08 18 10C18 6.65002 15.92 3.77002 12.99
      2.59003V3C12.99 4.10004 12.09 5 10.99 5H8.98999V7C8.98999 7.55005 8.54004
      8 7.98999 8H5.98999V10H11.99C12.54 10 12.99 10.45 12.99 11V14H13.99Z"
      {fill}
      fill-opacity="0.95" />
  </svg>

</Icon>
