<script>
  import Icon from "./Icon.svelte";

  export let width;
  export let height;
  export let fill;
  export let bg = false;
</script>

<Icon {width} {height} {bg}>
  <svg
    {width}
    {height}
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.21512 5.72L9.88512 0.0499992L12.0001 2.165L4.21512 9.95L0.00012207
      5.735L2.11512 3.62L4.21512 5.72ZM10.5851 2.165L9.88511 1.465L4.21511
      7.135L2.11511 5.035L1.41511 5.735L4.21511 8.535L10.5851 2.165Z"
      {fill} />
  </svg>

</Icon>
