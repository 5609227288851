<script>
  import Icon from "./Icon.svelte";

  export let width;
  export let height;
  export let fill;
  export let bg = false;
</script>

<Icon {width} {height} {bg}>
  <svg
    {width}
    {height}
    viewBox="0 0 76 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M64.875 0.375L64.875 11.125L75.625 11.125L75.625 18.2917L64.875
      18.2917L64.875 29.0058C64.875 29.0058 57.7442 29.0417 57.7083
      29.0058L57.7083 18.2917L46.9583 18.2917C46.9583 18.2917 46.9942 11.1608
      46.9583 11.125L57.7083 11.125L57.7083 0.375L64.875 0.375ZM57.7083
      68.4583L7.54167 68.4583L7.54167 18.2917L39.7917 18.2917L39.7917
      11.125L7.54167 11.125C3.6 11.125 0.375 14.35 0.375 18.2917L0.375
      68.4583C0.375 72.4 3.6 75.625 7.54167 75.625L57.7083 75.625C61.65 75.625
      64.875 72.4 64.875 68.4583L64.875 36.2083L57.7083 36.2083L57.7083
      68.4583ZM29.7942 57.0992L22.7708 48.6425L12.9167 61.2917L52.3333
      61.2917L39.6483 44.4142L29.7942 57.0992Z"
      {fill} />
  </svg>

</Icon>
