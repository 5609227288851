<script>
  import Icon from "./Icon.svelte";

  export let width;
  export let height;
  export let fill;
  export let bg = false;
  export let border = false;
</script>

<Icon {width} {height} {bg} {border}>
  <svg
    {width}
    {height}
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 8C16.21 8 18 6.21 18 4C18 1.79 16.21 0 14 0C11.79 0 10 1.79 10 4C10
      6.21 11.79 8 14 8ZM14 2C15.1 2 16 2.9 16 4C16 5.1 15.1 6 14 6C12.9 6 12
      5.1 12 4C12 2.9 12.9 2 14 2ZM6 14C6 11.34 11.33 10 14 10C16.67 10 22 11.34
      22 14V16H6V14ZM8 14C8.22 13.28 11.31 12 14 12C16.7 12 19.8 13.29 20
      14H8ZM5 8V11H3V8H0V6H3V3H5V6H8V8H5Z"
      {fill} />
  </svg>
</Icon>
