<script>
  import Icon from "./Icon.svelte";

  export let width;
  export let height;
  export let fill;
  export let bg = false;
</script>

<Icon {width} {height} {bg}>
  <svg
    {width}
    {height}
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.13046 6.26041L1.69796 3.82791L0.869629 4.65041L4.13046
      7.91124L11.1305 0.911245L10.308 0.0887451L4.13046 6.26041Z"
      {fill} />
  </svg>
</Icon>
