<script>
  import Icon from "./Icon.svelte";

  export let width;
  export let height;
  export let fill;
  export let bg = false;
</script>

<Icon {width} {height} {bg}>
  <svg
    class="calendarIcon"
    {width}
    {height}
    viewBox="0 0 10 12"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.99996 1.36298L8.49996 1.36298L8.49996 0.362976L7.49996
      0.362976L7.49996 1.36298L2.49996 1.36298L2.49996 0.362976L1.49996
      0.362976L1.49996 1.36298L0.999962 1.36298C0.449962 1.36298 -3.81377e-05
      1.81298 -3.81341e-05 2.36298L-3.80827e-05 10.363C-3.80792e-05 10.913
      0.449962 11.363 0.999962 11.363L8.99996 11.363C9.54996 11.363 9.99996
      10.913 9.99996 10.363L9.99996 2.36298C9.99996 1.81298 9.54996 1.36298
      8.99996 1.36298ZM8.99995 10.363L0.999953 10.363L0.999953 4.86298L8.99995
      4.86298L8.99995 10.363ZM0.999953 3.86298L8.99995 3.86298L8.99995
      2.36298L0.999953 2.36298L0.999953 3.86298Z"
      {fill} />
  </svg>
</Icon>
