<script>
  import Icon from "./Icon.svelte";

  export let width;
  export let height;
  export let fill;
  export let bg = false;
</script>

<Icon {width} {height} {bg}>
  <svg
    class="locationPinIcon"
    {width}
    {height}
    viewBox="0 0 8 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.00001 0.159241C2.06501 0.159241 0.500011 1.72424 0.500011
      3.65924C0.500011 6.28424 4.00001 10.1592 4.00001 10.1592C4.00001 10.1592
      7.50001 6.28424 7.50001 3.65924C7.50001 1.72424 5.93501 0.159241 4.00001
      0.159241ZM1.5 3.65924C1.5 2.27924 2.62 1.15924 4 1.15924C5.38 1.15924 6.5
      2.27924 6.5 3.65924C6.5 5.09924 5.06 7.25424 4 8.59924C2.96 7.26424 1.5
      5.08424 1.5 3.65924ZM2.75001 3.65924C2.75001 2.96888 3.30965 2.40924
      4.00001 2.40924C4.44659 2.40924 4.85925 2.64749 5.08254 3.03424C5.30583
      3.42099 5.30583 3.89749 5.08254 4.28424C4.85925 4.67099 4.44659 4.90924
      4.00001 4.90924C3.30965 4.90924 2.75001 4.3496 2.75001 3.65924Z"
      {fill} />
  </svg>
</Icon>
